// const logTracking = (eventTitle, cat, act, lab) => {
// 	console.log("%c event: ", "color: red", eventTitle)
// 	console.log("%c category: ", "color: red", cat)
// 	console.log("%c action: ", "color: red", act)
// 	console.log("%c label: ", "color: red", lab)
// 	console.log("%c -----------: ", "color: blue")
// }

export const gtmTrack = (
	eventTitle,
	eventCategory,
	eventAction,
	eventLabel
) => {
	// logTracking(eventTitle, eventCategory, eventAction, eventLabel)

	window.dataLayer.push({
		event: eventTitle,
		eventCategory,
		eventAction,
		eventLabel,
	})
}

export const gtmTrackVideo = (
	interactionName,
	currentPercentage,
	currentTime,
	videoDuration,
	videoTitle,
	videoId,
	videoUrl
) => {
	// category: Video
	// action: Start - [Video Name] / Watch to 10% - [Video Name]
	// label: [Video File Path] // do this in gtm

	const title = typeof videoTitle === "object" ? videoTitle.props.children : videoTitle

	// console.log("%c event: ", "color: red", 'videoTrack')
	// console.log("%c category: ", "color: red", "Video")
	// console.log("%c action: ", "color: red", interactionName + " - " + title)
	// console.log("%c videoName: ", "color: red", title)
	// console.log("%c videoTitle: ", "color: red", title)
	// console.log("%c videoDuration: ", "color: red", videoDuration)
	// console.log("%c videoId: ", "color: red", videoId)
	// console.log("%c videoPercentage: ", "color: red", currentPercentage + "%")
	// console.log("%c videoLapsedTime: ", "color: red", currentTime)
	// console.log("%c -----------: ", "color: blue")

	let videoCustomMatric = interactionName === "Start" ? 1 : 12
	if (interactionName.includes("%")) {
		videoCustomMatric = currentPercentage / 10 + 1
	}

	window.dataLayer.push({
		event: "videoTrack",
		eventCategory: "Video",
		eventAction: interactionName + " - " + title || videoUrl,
		eventLabel: videoUrl,
		attributes: {
			videoName: title,
			videoTitle: title,
			videoDuration: Math.round(videoDuration),
			videoId: videoId,
			videoPercentage: currentPercentage + "%",
			videoLapsedTime: Math.round(currentTime),
			videoCustomMatric: videoCustomMatric,
		},
	})
}

export const gtmSubmit = formName => {
	//2.1.14
	// Category – Form
	// Action – $Form Name - $Button Text (or Submit)
	// Label –  (No Label needed assuming the following success page has a unique success URL, tracked as a GA pageview.)
	gtmTrack("formSubmit", "Form", formName + " - Success", null)
}

export const gtmTrackFormInteractions = (formName, formClass) => {
	//2.1.12
	// Category – Form
	// Action – $Form Name – $Field Name
	// Label – Interaction  (no value collected, only an indication of an interaction)

	var form = document.querySelector("." + formClass)

	var onFieldInteract = (input, value) => {
		var fieldName = input.name
		var fieldValue = input.checked || input.value
		if (fieldName && fieldValue) {
			gtmTrack(
			"formInteraction",
			"Form",
			formName + " - " + fieldName,
			value || "Interaction"
			)
		}
	}

	if (form) {
		var el_inputs_withPii = form.querySelectorAll( "input[type=text], textarea" )
		el_inputs_withPii.forEach(input => {
			input.addEventListener("blur", () => onFieldInteract(input), true)
		})

		var el_inputs_noPii = form.querySelectorAll( "input[type=radio], input[type=checkbox], select" )
		el_inputs_noPii.forEach(input => {
			input.addEventListener("click", () => onFieldInteract(input, input.checked ? input.value : input.value + "-unchecked"), true)
		})
	}
}

export const gtmTrackFormQuizFieldSubmit = (fields) => {
	const quizFieldsArr = ['nightTimeSleep', 'restOfDayAffected', 'restOfDayNap', 'wakingUpHelp', 'wakingUpIs', 'wakingUpPrevNightSleep']
	Object.keys(fields).forEach(field => {
		if (quizFieldsArr.includes(field)){
			gtmTrack(
				"formInteraction",
				"FormQuizFieldSubmit",
				field,
				fields[field]
			)
		}
	})
}

export const gtmTrackFormErrors = (formName, errors) => {
	// 3.2
	// Category: Form Error
	// Action: Fields
	// Label: [Error Message1, Error Message 2, Error Message 3, etc.]  (red text)
	const errorMessages = Object.keys(errors).join(", ")
	gtmTrack("formError", "FormError", formName, errorMessages)
}

export const gtmTrackVideoProgress = (
	progress,
	everyXPercent,
	trackedPercent,
	videoTitle,
	duration,
	videoId,
	videoUrl
) => {
	const currentPercentage = Math.floor((progress.played * 100) / 10) * 10
	const currentSeconds = Math.floor(progress.playedSeconds)

	if (
		currentPercentage % everyXPercent === 0 &&
		currentPercentage > 0 &&
		!trackedPercent.includes(currentPercentage)
	) {
		gtmTrackVideo(
			"Watch to " + currentPercentage + "%",
			currentPercentage,
			currentSeconds,
			duration,
			videoTitle,
			videoId,
			videoUrl
		)
		trackedPercent.push(currentPercentage)
	}
}


const debounce = (func, wait, immediate) => {
	let timeout = null

	return function () {
		const context = this
		const args = arguments

		const later = () => {
			timeout = null

			if (!immediate) {
			func.apply(context, args)
			}
		}

		const callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) {
			func.apply(context, args)
		}
	}
}

export const gtmTrackScroll = () => {
	let distanceScrolled = -1
	let distanceThreshold = [
		{ threshold: 10, value: "10%", sent: false },
		{ threshold: 25, value: "25%", sent: false },
		{ threshold: 50, value: "50%", sent: false },
		{ threshold: 75, value: "75%", sent: false },
		{ threshold: 90, value: "90%", sent: false },
		{ threshold: 100, value: "100%", sent: false },
	]

	const scrollDepth = () => {
		const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
		let progress = Math.round( (scrollTop / (document.body.scrollHeight - window.innerHeight)) * 100 )

		if (isNaN(progress)) progress = 0

		if (progress > distanceScrolled) {
			distanceScrolled = progress

			for (let i = 0; i < distanceThreshold.length; i++) {
				const status = distanceThreshold[i].sent
				const threshold = distanceThreshold[i].threshold
				const value = distanceThreshold[i].value

				if (!status && progress >= threshold) {
					if ("dataLayer" in window) {
						// console.log("%c event: ", "color: red", 'gatsbyScroll')
						// console.log("%c gatsbyScrollDistance: ", "color: red", value)
						// console.log("%c -----------: ", "color: blue")

						window.dataLayer.push({
							event: "gatsbyScroll",
							gatsbyScrollDistance: value,
						})
						distanceThreshold[i].sent = true
					}
				}
			}
		}
	}
	const scrollListener = debounce(scrollDepth, 18)
	document.addEventListener("scroll", scrollListener)
	scrollDepth()
}
